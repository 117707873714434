@import "_Common.scss";

.Footer {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Footer-map {
    width: 100%;
    display: flex;
    flex-direction: row;
    h1 {
        font-size: 14px;
        font-weight: bolder;
        margin: 0 0 10px 0;
    }
}

.Footer-link {
    font-size: 12px;
    margin: 15px 0 0 0;
}

.Footer-map-column {
    display: flex;
    flex-direction: column;
    margin: 0 60px 0 0;
}

.Footer-menu {
    margin: 0 0 50px 0;
    display: flex;
    flex-direction: row;
}

.Footer-copy {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    margin: 0 60px 0 0;
}

.Footer-logoText
{
    display: flex;
    flex-direction: row;
}

.Footer-logo {
    height: 40px;
}

@mixin EmailSubscribe-inputs-tmpl($bkcolor) {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 300px;
    margin: 10px 0 0 0;
    padding: 0 0 0 5px;
    border-radius: 40px;
    background-color: white;
    border: 2px solid $bkcolor;
    input {
        margin: 0 10px 0 10px;
        display: block;
        width: 200px;
        height: 20px;
        background-color: transparent;
        border: 0;
        font-size: 14px;
    }
}

.EmailSubscribe-inputs {
    @include EmailSubscribe-inputs-tmpl($BackgroundWhiteColor);
}

.EmailSubscribe-inputs-red {
    @include EmailSubscribe-inputs-tmpl(#ff000060);
}

.EmailSubscribe-notification {
    margin: 5px 0 0 10px;
    font-size: 10px;
    color: red;
}

.Copyright {
    display: flex;
    flex-direction: row;
    align-items: center;
}
