@import "_Common.scss";

.Terms-bg {
    background-color: $BackgroundWhiteColor;
}

.Terms {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}
