@import "_Common.scss";

.StaticSyncFeatures-bg {
    background-color: $BackgroundHiWhiteColor;
}

.StaticSyncFeatures {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.StaticSyncFeatures-list {
    display: flex;
    flex-direction: column;
}

.StaticSyncFeatures-list-item {
    display: flex;
    flex-direction: row;
    margin: 0 0 20px 0;
    justify-content: flex-start;
}

$IconWidth: 40px;
.StaticSyncFeatures-feature-icon {
    width: $IconWidth;
    height: $IconWidth;
    margin: 0 10px 0 0;
}
