@import "_Common.scss";

.OldProducts-bg {
    background-color: $BackgroundHiWhiteColor;
}

.OldProducts {
    margin: $SectionMarginHeight 0 $SectionMarginHeight;
    color: $BlackTextColor;
}
