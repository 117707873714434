@import "_Common.scss";

$BlogPanelWidth: 250px;

.BlogIndex-bg {
    background-color: $BackgroundWhiteColor;
}

.BlogIndex {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    display: flex;
    flex-direction: column;
    color: $BlackTextColor;
}

.BlogList {
    display: flex;
    flex-direction: row;
    widtH: 100%;
    flex-wrap: wrap;
}

.BlogRow {
    display: flex;
    flex-direction: row;
    margin: 50px 0 0 0;
}

.BlogPanel {
    display: flex;
    flex-direction: column;
    margin: 0 50px 0 0;
    a {
        color: $BlackTextColor;
    }
}

.BlogHead {
    padding: 10px 0 0 0;
    width: $BlogPanelWidth;
    height: 200px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: $ListItemColor1;
}

.BlogTitle {
    text-align: center;
    margin: 10px;
}

.BlogBrief {
    margin: 0 20px 0 20px;
}

.BlogFooter {
    padding: 20px 0 0 0;
    width: $BlogPanelWidth;
    height: 50px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: $ListItemColor2;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.BlogTime {
    color: $TextColor;
}
