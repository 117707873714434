@import "_Common.scss";

body {
    background-color: $BackgroundColor;
    margin: 0;
    font-family: "Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
        "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    font-size: 15px;
    color: $TextColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 23px;
}

a {
    color: $LinkColor;
    text-decoration: none;
    &:hover {
        color: $HighLinkColor;
    }
}

h1 {
    font-size: 30px;
    line-height: 35px;
}

h2 {
    font-size: 25px;
}

h3 {
    font-size: 20px;
}
