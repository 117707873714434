@import "_Common.scss";

.DownloadAction-bg {
    background-color: $BackgroundWhiteColor;
}

.DownloadAction {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}
