@import "_Common.scss";

.Blog-bg {
    background-color: $BackgroundWhiteColor;
}

.Blog {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.BlogContent {
    width: 700px;
    margin: 0 0 100px 0;
}
