@import "_Common.scss";
@import "./Banner.scss";

.Backup9Banner-bg {
    background-color: $BackgroundWhiteColor;
}

.Backup9Banner {
    margin: 100px 0 0 0;
    @include Banner-tmpl("./Backup9.png", 500px, 0, 100%);
    color: $BlackTextColor;
    h2 {
        color: $GrayTextColor;
    }
}
