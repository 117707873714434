@import "_Common.scss";
@import "./Banner.scss";

.StaticSyncBanner-bg {
    background-color: $BackgroundHiWhiteColor;
}


@mixin StaticSyncBanner-tmpl($bkfile, $percent) {
    margin: 100px 0 0 0;
    @include Banner-tmpl($bkfile, 0, 350px, 100%);
    color: $BlackTextColor;
    h2 {
        color: $GrayTextColor;
    }
    height: 900px;
}

.StaticSyncBanner {
    @include StaticSyncBanner-tmpl("./StaticSyncScreens.png", 100%);
}

.StaticSyncBanner_macOS {
    @include StaticSyncBanner-tmpl("./StaticSyncScreens_macOS.png", 100%);
}

.StaticSyncBanner-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.StaticSyncBanner-intro {
    h1,
    h2 {
        margin: 20px;
    }
    width: 600px;
}
