@import "_Common.scss";

.Header {
    background-color: $BackgroundDarkColor;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

$TopPaddingForA: 7px;

.Header-bg {
    position: fixed;
    background-color: $BackgroundDarkColor;
}

.Header-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Header-logo {
    margin: 0 5px 0 0;
    height: 20px;
}

.Header-logo-text {
    color: $TextHighlightColor;
}

.Header-social {
    padding-top: $TopPaddingForA;
    display: block;
}
