@import "_Common.scss";

.ProductsDocument-bg {
    background-color: $BackgroundWhiteColor;
}

.ProductsDocument {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.ProductsDocument-list {
    display: flex;
    flex-direction: column;
}

@mixin ProductsDocument-list-item-tmpl($bkcolor) {
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color: $bkcolor;
}

.ProductsDocument-list-item1 {
    @include ProductsDocument-list-item-tmpl($ListItemColor1);
}

.ProductsDocument-list-item2 {
    @include ProductsDocument-list-item-tmpl($ListItemColor2);
}

.ProductsDocument-list-line {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
}

.ProductsDocument-link {
    display: block;
    margin: 0 30px 0 0;
}
