@import "_Common.scss";

@mixin More-tmpl($padwidth) {
    margin: 0 0 0 $padwidth;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    a {
        font-family: "Raleway", sans-serif;
        height: 22px;
        &:hover {
            text-decoration: underline;
        }
        margin: 0;
    }
    font-size: 16px;
}

.More {
    @include More-tmpl(10px);
}

.More-nopad {
    @include More-tmpl(0);
}

.More-brace {
    margin: 0 0 0 5px;
    font-weight: bolder;
    height: 22px;
}

@mixin ButtonPanel-tmpl($dir) {
    display: flex;
    flex-direction: $dir;
    align-items: center;
}

.ButtonPanel {
    @include ButtonPanel-tmpl(row);
}

.ButtonPanel-column {
    @include ButtonPanel-tmpl(column);
    height: 70px;
    justify-content: space-between;
}
