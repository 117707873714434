@import "_Common.scss";

.Backup8SupportsSoftware-bg {
    background-color: $BackgroundHiWhiteColor;
}

.Backup8SupportsSoftware {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.Backup8SupportList {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.Backup8SupportListEntry {
    margin: 0 40px 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
        margin: 0 0 7px 0;
        width: 24px;
    }
}
