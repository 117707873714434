@import "_Common.scss";
@import "./Banner.scss";

.StaticSyncBrief-bg {
    background-color: $BackgroundWhiteColor;
}

@mixin StaticSyncBrief-tmpl($bkfile) {
    margin: $SectionMarginHeight 0 0 0;
    color: $BlackTextColor;
    @include Banner-tmpl($bkfile, 0, 350px, 100%);
    height: 900px;
}

.StaticSyncBrief {
    @include StaticSyncBrief-tmpl("./StaticSyncScreens.png");
}

.StaticSyncBrief_macOS {
    @include StaticSyncBrief-tmpl("./StaticSyncScreens_macOS.png");
}

.StaticSyncBrief-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
