@import "_Common.scss";

.Privacy-bg {
    background-color: $BackgroundWhiteColor;
}

.Privacy {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}
