@import "_Common.scss";

.FreeUpgradeBackup8-bg {
    background-color: $BackgroundWhiteColor;
}

.FreeUpgradeBackup8 {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.FreeUpgradeBackup8-fields {
    width: 600px;
    display: flex;
    flex-direction: column;
}

.FreeUpgradeBackup8-input {
    margin: 0 0 10px 0;
    height: 30px;
    border: 0;
    padding: 5px;
}

.FreeUpgradeBackup8-button-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 20px 0 0 0;
    h3 {
        margin: 0 0 0 30px;
    }
}
