@import "_Common.scss";

.PurchasePage-bg {
    background-color: $BackgroundWhiteColor;
}

.PurchasePage {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.PurchasePage-list {
    display: flex;
    flex-direction: column;
}

.PurchasePage-product-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 80px 0;
}

@mixin PurchasePage-list-item-tmpl($bkcolor) {
    padding: 30px;
    display: flex;
    flex-direction: column;
    background-color: $bkcolor;
    width: 400px;
    margin: 0 50px 0 0;
}

.PurchasePage-list-column {
    display: flex;
    flex-direction: row;
}

.PurchasePage-list-item1 {
    @include PurchasePage-list-item-tmpl($ListItemColor1);
}

.PurchasePage-list-item2 {
    @include PurchasePage-list-item-tmpl($ListItemColor2);
}

.PurchasePage-list-item-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PurchasePage-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.PurchasePage-product-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

$PanelBorderRadius: 20px;
$PanelWidth: 430px;
$PanelPadding: 10px;

.PurchasePage-product-panel-container {
    display: flex;
    flex-direction: column;
}

.PurchasePage-product-panel {
    width: $PanelWidth;
    height: 800px;
    background-color: $ListItemColor1;
    padding: 50px 10px 20px 10px;
    border-top-left-radius: $PanelBorderRadius;
    border-top-right-radius: $PanelBorderRadius;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PurchasePage-product-panel-footer {
    width: $PanelWidth;
    height: 200px;
    padding: $PanelPadding;
    background-color: $ListItemColor1;
    border-bottom-left-radius: $PanelBorderRadius;
    border-bottom-right-radius: $PanelBorderRadius;
}

.PurchasePage-product-license-type {
    text-align: center;
    font-size: 20px;
    font-weight: bolder;
}

.PurchasePage-product-price {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 40px;
    vertical-align: baseline;
    font-weight: bolder;
    .major {
        font-size: 60px;
    }
}

.PurchasePage-oslogos {
    margin: 30px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
        width: 25px;
        margin:  0 5px 0 5px;
    }
}

.PurchasePage-buy-type {
    margin: 30px 0 10px 0;
    height: 36px;
    display: flex;
    flex-direction: row;
    select {
        font-size: 20px;
        border: 0;
        padding: 5px;
        padding: 5px;
        border: 1px solid transparent;
        &:hover {
            border: 1px solid $TextColor;
        }
    }
}

.PurchasePage-feature-list {
    margin: 30px 40px 0 0;
    li {
        margin: 0 0 15px 0;
    }
}

.PurchasePage-buy-button-panel {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.PurchasePage-benefit-container {
    margin: 50px 0 0 0;
}
