@import "_Common.scss";

.StaticSyncSupportClouds-bg {
    background-color: $BackgroundHiWhiteColor;
}

.StaticSyncSupportClouds {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.StaticSyncCloudLogos {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
        width: auto;
        height: 30px;
        margin: 0 20px 0 0;
    }
}
