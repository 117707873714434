@import "_Common.scss";

.Backup9Desc-bg {
    background-color: $BackgroundWhiteColor;
}

.Backup9Desc {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.Backup9Desc-features {
    margin: 50px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Backup9Desc-feature-entry {
    margin: 0 50px 0 0;
    display: flex;
    flex-direction: column;
}

.Backup9Desc-feature-entry-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
