@import "_Common.scss";

.Company-bg {
    background-color: $BackgroundWhiteColor;
}

.Company {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}
