@import "_Common.scss";

.AboutHighlights-bg {
    background-color: $BackgroundHiWhiteColor;
}

.AboutHighlights {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.AboutHighlights-highlight {
    margin: 0 50px 0 0;
}

.AboutHighlights-content {
    display: flex;
    flex-direction: row;
}
