@import "_Common.scss";

@mixin Banner-tmpl($bgimg, $bgposx, $bgposy, $bgsize) {
    background-image: url($bgimg);
    background-repeat: no-repeat;
    background-position: $bgposx $bgposy;
    background-size: $bgsize;
}

.Banner-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
        margin: 0 5px 0 0;
        width: 30px;
    }
}

.Banner-intro-text {
    display: flex;
    flex-direction: column;
}

@mixin Banner-button-panel-tmpl($align) {
    margin: 20px 0 100px 0;
    display: flex;
    flex-direction: row;
    justify-content: $align;
}

.Banner-button-panel {
    @include Banner-button-panel-tmpl(flex-start);
}

.Banner-button-panel-center {
    @include Banner-button-panel-tmpl(center);
}

.Banner-icons {
    margin: 10px 0 30px 0;
    img {
        height: 20px;
        margin: 0 2px 0 2px;
    }
}
