@import "_Common.scss";

.Support-bg {
    background-color: $BackgroundHiWhiteColor;
}

.Support {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
    display: flex;
    flex-direction: column;
}

.Support-list {
    display: flex;
    flex-direction: row;
}

.Support-list-item {
    margin: 0 30px 0 0;
    padding: 30px;
    display: flex;
    flex-direction: column;
    p {
        height: 80px;
    }
    h2 {
        margin: 0;
    }
    border-radius: 10px;
    background-color: $ListItemColor2;
    width: 350px;
}
