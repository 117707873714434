@import "_Common.scss";
@import "./Banner.scss";

.Backup8Banner-bg {
    background-color: $BackgroundHiWhiteColor;
}

.Backup8Banner {
    margin: 100px 0 0 0;
    @include Banner-tmpl("./Backup8.png", 500px, 0, 100%);
    color: $BlackTextColor;
    h2 {
        color: $GrayTextColor;
    }
}
