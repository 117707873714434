@import "_Common.scss";

.FAQ-bg {
    background-color: $BackgroundWhiteColor;
}

.FAQ {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
    h3 {
        display: block;
        margin: 50px 0 0 0;
        color: $RedTextColor;
        font-weight: bold;
    }
}
