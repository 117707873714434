@import "_Common.scss";

h1 {
    font-size: 18px;
}
 
.Help-bg {
    background-color: $BackgroundWhiteColor;
}

.Help {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
    display: flex;
    flex-direction: row;
}

.Help-sidebar {
    width: 250px;
    margin: 0 30px 0 0;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    h3 {
        margin: 0 0 10px 0;
        padding: 0 0 5px 0;
        border-bottom: 1px solid $GrayTextColor;
    }
    h4 {
        margin: 0 0 10px 0;
    }
    p {
        margin: 0 0 10px 10px;
    }
    ul {
        margin: 0 0 10px 0;
    }
}

.Help-content {
    padding: 0 0 0 30px;
    border-left: 1px dotted $GrayTextColor;
    h3 {
        margin: 0;
    }
}

.Help-specific-info {
    font-size: 12px;
    font-weight: bolder;
    color: $RedTextColor;
}
