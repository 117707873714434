$BackgroundColor: #202020;
$BackgroundWhiteColor: #eaeaea;
$BackgroundLoWhiteColor: #cccccc;
$BackgroundHiWhiteColor: #e0e0e0;
$BackgroundDarkColor: #333333;

$ListItemColor1: #e3e3e3;
$ListItemColor2: #f0f0f0;

$RECT1_BACKGROUND_COLOR: #f2f2f2;
$RECT2_BACKGROUND_COLOR: #fcfcfc;

$ChartBackgroundColor1: #cccccc;
$ChartBackgroundColor2: #dddddd;

$BlackTextColor: #333333;
$GrayTextColor: #555555;
$TextColor: #aaaaaa;
$TextHighlightColor: #eeeeee;
$RedTextColor: #880000;

$RedButtonColor: #880000;
$RedButtonColorHigh: #aa0000;

$GreenButtonColor: #008855;
$GreenButtonColorHigh: #00aa55;

$BlueButtonColor: #ff8855;
$BlueButtonColorHigh: #ffaa55;

$GrayButtonColor: #ababab;
$GrayButtonColorHigh: $GrayButtonColor;

$LinkColor: #c56b17;
$HighLinkColor: #eb9b5b;

$ContentWidth: 960px;
$SectionMarginHeight: 70px;

$SmallTitleTextSize: 20px;
$TinyTextSize: 13px;

.Bold {
    font-weight: bolder;
}

.BoldText {
    font-family: "Righteous", cursive;
}

.AdWords {
    font-family: "Kanit", sans-serif;
}

.LeftText {
    text-align: left;
}

.RightText {
    text-align: right;
}

.CenterText {
    text-align: center;
}

.SmallText {
    font-size: 12px;
}

.FlexAlignCenter {
    align-items: center;
}

.FlexJustifyCenter {
    justify-content: center;
}

.Quotes {
    font-style: italic;
    &:before {
        content: open-quote;
    }
    &:after {
        content: close-quote;
    }
}

@mixin SmallTitle($color) {
    color: $color;
    font-size: $SmallTitleTextSize;
    font-weight: bold;
}

.SmallTitle {
    @include SmallTitle($TextHighlightColor);
}

.SmallTitle-gray {
    @include SmallTitle($GrayTextColor);
}

.Section {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Content {
    width: $ContentWidth;
}

.SystemLogo {
    width: 20px;
    margin: 0 5px 0 5px;
}

.StoreImage {
    width: 180px;
}

.ProductLogo {
    margin: 0 5px 0 5px;
    width: 30px;
    height: 30px;
}

.ProductLogoBigger {
    margin: 0 10px 0 10px;
    width: 50px;
    height: 50px;
}

.LineThrough {
    text-decoration: line-through;
}

.RedText {
    color: $RedTextColor;
}
