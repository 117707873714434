@import "_Common.scss";

$ScreenshotsSectionWidth: 700px;
$MarginAfterAScreenshot: 70px;

.Screenshots {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
}

.Screenshots-bg {
  background-color: $BackgroundWhiteColor;
}

.Screenshots-img {
  width: $ScreenshotsSectionWidth;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 $MarginAfterAScreenshot 0;
  img {
    width: 100%;
  }
}

.Screenshot-desc {
  margin: 20px 0 0 0;
  height: 100px;
  color: $GrayTextColor;
}

.Screenshots-switch-btns {
  display: flex;
  flex-direction: row;
  margin: 0 0 30px 0;
  cursor: pointer;
}

@mixin Screenshots-btn-tmpl($color) {
    width: 50px;
    height: 10px;
    border-radius: 5px;
    background-color: $color;
    margin: 10px;
}

$ScreenSwitchBtnColor: #aaaaaa;
$ScreenSwitchBtnColorHigh: #333333;

.Screenshots-switch-btn {
    @include Screenshots-btn-tmpl($ScreenSwitchBtnColor);
}

.Screenshots-switch-btn-hl {
    @include Screenshots-btn-tmpl($ScreenSwitchBtnColorHigh);
}
