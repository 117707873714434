@import "_Common.scss";

.Purchase {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $BlackTextColor;
}

.Purchase-bg {
    background-color: $BackgroundHiWhiteColor;
}

.Purchase-panel {
    margin: 50px 0 50px 0;
    padding: 50px 0 50px 0;
    width: 300px;
    border-radius: 10px;
    border: 2px solid $TextColor;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Purchase-icon {
    display: block;
    float: left;
    width: 80px;
    margin: 0 0 30px 0;
}

.Purchase-saved {
    color: red;
    margin: 0 0 30px 0;
}

@mixin Purchase-cards-tmpl($bkcolor) {
    padding: 5px;
    display: flex;
    flex-direction: row;
    width: 130px;
    justify-content: space-between;
    background-color: $bkcolor;
    border-radius: 5px;
}

.Purchase-cards {
    @include Purchase-cards-tmpl($BackgroundWhiteColor);
}

.Purchase-cards-dark {
    @include Purchase-cards-tmpl($BackgroundDarkColor);
}

.Purchase-original-price {
    text-decoration: line-through;
    color: $GrayTextColor;
}

.Purchase-discount {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: space-between;
}

.Purchase-benefits {
    width: 100%;
    margin: 10px 0 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.Purchase-benefit-item {
    margin: 5px;
    display: flex;
    flex-direction: row;
    font-size: 12px;
}

.SupportBanner-bg {
    background-color: $BackgroundWhiteColor;
}

.SupportBanner-hibg {
    background-color: $BackgroundHiWhiteColor;
}

.SupportBanner {
    display: flex;
    flex-direction: row;
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
}

.SupportBanner-desc {
    color: $GrayTextColor;
    width: 500px;
}

.SupportBanner-girl {
    width: 400px;
    height: 378px;
    margin-right: 50px;
}

.SupportBanner-buttons {
    display: flex;
    flex-direction: row;
}

@mixin Product-list-tmpl() {
    display: flex;
    flex-direction: column;
}

.Products-list {
    @include Product-list-tmpl();
}

@mixin Products-item-tmpl($bkcolor) {
    display: flex;
    flex-direction: column;
    background-color: $bkcolor;
    padding: 30px;
}

.Products-item1 {
    @include Products-item-tmpl($ListItemColor1);
}

.Products-item2 {
    @include Products-item-tmpl($ListItemColor2);
}

.Products-item-line {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.Products-item-icon {
    margin: 0 5px 0 0;
    width: 30px;
    height: 30px;
}

.Products-item-name {
    font-weight: bolder;
}

.Products-item-version {
    color: $GrayTextColor;
}

.Products-item-more {
    margin-left: auto;
}

@mixin Products-item-download-rect-tmpl($bkcolor) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    padding: 30px 20px 20px 20px;
    border-radius: 10px;
    width: 400px;
    background-color: $bkcolor;
}

.Products-item-download-rect1 {
    @include Products-item-download-rect-tmpl($RECT1_BACKGROUND_COLOR);
}

.Products-item-download-rect2 {
    @include Products-item-download-rect-tmpl($RECT2_BACKGROUND_COLOR);
}

.Products-item-operating-system-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}
