@import "_Common.scss";

$ButtonWidth: 100px;
$ButtonHeight: 40px;
$ButtonFontSize: 15px;

$ButtonBigWidth: 280px;
$ButtonBigHeight: 60px;
$ButtonBigFontSize: 20px;

@mixin ButtonTmpl($fontSize, $color, $bgcolor, $bgcolorHigh, $width, $height, $cursor) {
    cursor: pointer;
    font-size: $fontSize;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: $width;
    height: $height;
    color: $color;
    background-color: $bgcolor;
    border-radius: 50px;
    cursor: $cursor;
    &:hover {
        color: $color;
        background-color: $bgcolorHigh;
    }
    text-decoration: none;
}

.Button {
    @include ButtonTmpl($ButtonFontSize, $TextHighlightColor, $GreenButtonColor, $GreenButtonColorHigh, $ButtonWidth, $ButtonHeight, pointer);
}

.Button-big {
    @include ButtonTmpl($ButtonBigFontSize, $TextHighlightColor, $GreenButtonColor, $GreenButtonColorHigh, $ButtonBigWidth, $ButtonBigHeight, pointer);
}

.Button-blue {
    @include ButtonTmpl($ButtonFontSize, $TextHighlightColor, $BlueButtonColor, $BlueButtonColorHigh, $ButtonWidth, $ButtonHeight, pointer);
}

.Button-blue-big {
    @include ButtonTmpl($ButtonBigFontSize, $TextHighlightColor, $BlueButtonColor, $BlueButtonColorHigh, $ButtonBigWidth, $ButtonBigHeight, pointer);
}

.Button-gray {
    @include ButtonTmpl($ButtonFontSize, $GrayTextColor, $GrayButtonColor, $GrayButtonColorHigh, $ButtonWidth, $ButtonHeight, not-allowed);
}

.Button-gray-big {
    @include ButtonTmpl($ButtonBigFontSize, $GrayTextColor, $GrayButtonColor, $GrayButtonColorHigh, $ButtonBigWidth, $ButtonBigHeight, not-allowed);
}
