@import "_Common.scss";

.WhatsNew-bg {
    background-color: $BackgroundWhiteColor;
}

.WhatsNew {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

.WhatsNew-head-title {
    display: block;
    margin: 50px 0 0 0;
    color: $RedTextColor;
}
