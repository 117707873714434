.cls-1,
.cls-2 {
    fill: none;
}

.cls-1 {
    stroke: #1a1a1a;
    stroke-miterlimit: 10;
    stroke-width: 2px;
}

.icon {
    width: 40px;
    height: 40px;
}
