@import "_Common.scss";

.Backup8Welcome-bg {
    background-color: $BackgroundHiWhiteColor;
}

.Backup8Welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: $SectionMarginHeight 0 0 0;
    height: 600px;
    color: $BlackTextColor;
    background-image: url(./Backup8.png);
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: center 250px;
}
