@import "_Common.scss";

.Downloads-bg {
    background-color: $BackgroundWhiteColor;
}

.Downloads {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    color: $BlackTextColor;
}

@mixin Downloads-more-product-list-item-tmpl($bkcolor) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: $bkcolor;
    padding: 20px;
}

.Downloads-more-product-list-item1 {
    @include Downloads-more-product-list-item-tmpl($ListItemColor1);
}

.Downloads-more-product-list-item2 {
    @include Downloads-more-product-list-item-tmpl($ListItemColor2);
}

.Downloads-warning {
    color: red;
}

.Downloads-more-product-title {
    width: 500px;
}

.Downloads-more-product-size-download {
    display: flex;
    flex-direction: column;
    align-items: center;
}
