@import "_Common.scss";

.MenuItem {
    color: $TextColor;
    text-align: center;
    text-decoration: none;
    font-weight: bolder;
    border-bottom: 2px solid transparent;
    &:hover {
        color: $TextHighlightColor;
        text-decoration: none;
        border-bottom: 2px solid $TextHighlightColor;
    }
    &:visited {
        text-decoration: none;
    }
    &:active {
        color: $TextColor;
        text-decoration: none;
    }
}
