@import "_Common.scss";

.StaticSyncDownloads-bg {
    background-color: $BackgroundWhiteColor;
}

.StaticSyncDownloads {
    margin: $SectionMarginHeight 0 $SectionMarginHeight 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $BlackTextColor;
}

.StaticSyncDownloads-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
}

.StaticSyncDownloads-os-list {
    display: flex;
    flex-direction: row;
    margin: 10px 0 20px 0;
}

@mixin StaticSyncDownload-os-tmpl($bdcolor) {
    display: flex;
    flex-direction: row;
    margin: 0 10px 0 10px;
    padding: 0 0 10px 0;
    border-bottom: 3px solid $bdcolor;
    img {
        cursor: pointer;
    }
}

.StaticSyncDownloads-os {
    @include StaticSyncDownload-os-tmpl(transparent)
}

.StaticSyncDownloads-os-selected {
    @include StaticSyncDownload-os-tmpl($BlueButtonColor)
}

.StaticSyncDownloads-button-container {
    margin: 20px 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.StaticSyncDownload-store {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.StaticSyncDownloads-download-desc {
    width: 300px;
    text-align: center;
}

.StaticSyncDownloads-panel-title {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.StaticSyncDownloads-panel {
    margin: 0 50px 0 50px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
        font-size: $SmallTitleTextSize;
        color: $GrayTextColor;
        font-weight: bolder;
    }
}

.StaticSyncDownloads-panel-reqtitle {
    color: $GrayTextColor;
    font-weight: bolder;
}

.StaticSyncDownloads-panel-button {
    margin: 30px 0 0 0;
}

.StaticSyncDownloads-desc {
    display: flex;
    flex-direction: row;
    width: 300px;
    margin: 15px 0 0 45px;
}

.StaticSyncDownloads-desktop-img {
    margin: 20px;
    width: 300px;
}

.StaticSyncDownloads-system-img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}
